// @mui
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import Card, { CardProps } from '@mui/material/Card';
import Timeline from '@mui/lab/Timeline';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
// utils
import { fDateTime } from 'src/utils/format-time';
import { useLocales } from 'src/locales';

// ----------------------------------------------------------------------

type ItemProps = {
  id: string;
  title: string;
  time: Date | string | number;
  type: string;
};

interface Props extends CardProps {
  title?: string;
  subheader?: string;
  list: ItemProps[];
}

export default function MetricsStoreStateTimeline({ title, subheader, list, ...other }: Props) {
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <Timeline
        sx={{
          m: 0,
          p: 3,
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        {list.length === 0 && (
          <Typography variant="body2" sx={{ color: 'text.secondary', textAlign: 'center', py: 3 }}>
            No se produjeron cambios en el periodo seleccionado
          </Typography>
        )}
        {list.map((item, index) => (
          <OrderItem key={item.id} item={item} lastTimeline={index === list.length - 1} />
        ))}
      </Timeline>
    </Card>
  );
}

// ----------------------------------------------------------------------

type OrderItemProps = {
  item: any;
  lastTimeline: boolean;
};

function OrderItem({ item, lastTimeline }: OrderItemProps) {
  const { name, created_at } = item;
  const {t} = useLocales();
  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot
          color={
            (name === 'AutoPilot' && 'success') ||
            (name === 'Off' && 'error') ||
            'error'
          }
        />
        {lastTimeline ? null : <TimelineConnector />}
      </TimelineSeparator>

      <TimelineContent>
        <Typography variant="subtitle2">{t(`state.${name}`)}</Typography>

        <Typography variant="caption" sx={{ color: 'text.disabled' }}>
          {fDateTime(created_at)}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
}
