// @mui
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import { Stack, Tooltip, Typography } from '@mui/material';
import Iconify from 'src/components/iconify';

type Props = {
    headLabel: any[];
};
type HeadLabel = {
    id: string;
    label: string;
    align?: 'left' | 'right' | 'center';
    minWidth?: number;
    grouped?: string;
};

export default function MetricsTableHeader({ headLabel }: Props) {

    return (
        <TableHead>        
            <TableRow>
                {headLabel.map((column) => (
                    <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ top: 56, minWidth: column.minWidth }}
                    >
                        <Stack direction="row" alignItems="center">

                        <Typography variant="caption" fontSize={12} fontWeight={600}>{column.label}</Typography>
                        {column?.helpText && (
                            <Tooltip title={column.helpText}>
                                <Iconify icon="mdi:information-outline" style={{ marginLeft: 4 }} color="text.primary" />
                            </Tooltip>
                        )}
                        </Stack>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>



    );
}
