import { useState, useEffect } from 'react';
// @mui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { useRouter } from 'src/routes/hook';
import { paths } from 'src/routes/paths';
import axios, { API_ENDPOINTS } from 'src/utils/axios';
import { useSnackbar } from 'src/components/snackbar';
import Label from 'src/components/label';
import { showSpinner } from 'src/redux/slices/spinner';
import { useDispatch } from 'react-redux';
import { useLocales } from 'src/locales';
import { Mixpanel } from 'src/Mixpanel';
import { Drawer, List, ListItem, ListItemText, Tooltip } from '@mui/material';
import MetricsStoreStateTimeline from './metrics-store-state-timeline';
import MetricsInstanceTimeline from './metrics-instance-timeline';

// ----------------------------------------------------------------------

type Props = {
  // row: IOrderItem;
  row: any;
};
export default function MetricsTableRow({
  row
}: Props) {
  const { id, channels, store, total_conversations, total_conversations_full_ai, percent_full_ai_total_conversations,
     total_conversations_merchant_interviend, total_conversations_with_ai, participation_rate_ai, participation_rate_full_ai, merchant_intervention_rate } = row;
  const { t } = useLocales();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const renderFlag = (country: string) => {
    switch (country) {
      case 'AR':
        return <Iconify icon="twemoji:flag-argentina" />;
      case 'MX':
        return <Iconify icon="twemoji:flag-mexico" />;
      case 'BR':
        return <Iconify icon="twemoji:flag-brazil" />;
      case 'CO':
        return <Iconify icon="twemoji:flag-colombia" />;
      case 'CL':
        return <Iconify icon="twemoji:flag-chile" />;
      default:
        return <Box>{country}</Box>;
    }
  }
  const renderPrimary = (
    <TableRow hover key={`i-${id}`}>
      <TableCell>
        <Box>{store?.name}</Box>
      </TableCell>
      <TableCell>
        <Box>
          {renderFlag(store?.country)}
        </Box>
      </TableCell>

      <TableCell>
        <Box>{total_conversations}</Box>
      </TableCell>
      <TableCell>
        <Box>{total_conversations_with_ai}</Box>
      </TableCell>
      <TableCell>
        <Box>{(participation_rate_ai * 100).toFixed(2)}%</Box>
      </TableCell>
      <TableCell>
        <Box>{total_conversations_full_ai}</Box>
      </TableCell>
      <TableCell>
        <Box>{(participation_rate_full_ai * 100).toFixed(2)}%</Box>
      </TableCell>
      <TableCell>
        <Box>{total_conversations_merchant_interviend}</Box>
      </TableCell>
      <TableCell>
        <Box>{(merchant_intervention_rate * 100).toFixed(2)}%</Box>
      </TableCell>

      <TableCell>
        <Box>{(percent_full_ai_total_conversations * 100).toFixed(2)}%</Box>
      </TableCell>
      <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
        <Tooltip title="Ver más" placement="top">
          <IconButton onClick={toggleDrawer(true)}>
            <Iconify icon="solar:eye-bold" />
          </IconButton>
        </Tooltip>
      </TableCell>

      {/* <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
        <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
          <Iconify icon="eva:more-vertical-fill" />
        </IconButton>
      </TableCell> */}
    </TableRow>
  );
  const getTimeline = () => {
    const filteredChannels = channels.filter((channel: any) => channel.history && channel.history.length > 0);
    const allHistories = filteredChannels.flatMap((channel: any) => 
      channel.history.map((historyItem: any) => ({ ...historyItem, username: channel.username }))
    );
    const sortedHistories = allHistories.sort((a: any, b: any) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
    return sortedHistories;
  }
  return (
    <>
      {renderPrimary}
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        sx={{
          "& .MuiDrawer-paper": {
            width: "50%"
          }
        }}
      >
        <Box
          sx={{ width: "100%" }}
          role="presentation"
          
        >
          <Box display="flex" justifyContent="flex-end" p={2}>
            <IconButton onClick={toggleDrawer(false)}>
              <Iconify icon="solar:close-circle-bold" />
            </IconButton>
          </Box>
          <Box sx={{ p: 3 }} display="flex" flexDirection="row" gap={3} justifyContent="center">
            <MetricsInstanceTimeline title="Historial instancias" subheader="Historial de cambio de conexiones de instancias" list={getTimeline()} />
            <MetricsStoreStateTimeline title="Historial asistente" subheader="Historial de cambios de modo de asistencia" list={store?.history} />
          </Box>
        </Box>
      </Drawer>
    </>
  );
}
