import { useCallback, useEffect, useRef, useState } from 'react';
// @mui
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
// types
import { IInvoiceTableFilters, IInvoiceTableFilterValue } from 'src/types/invoice';
// components
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { Box } from '@mui/material';
import axios, { API_ENDPOINTS } from 'src/utils/axios';

// ----------------------------------------------------------------------

type Props = {
  filters: IInvoiceTableFilters;
  onFilters: (name: string, value: IInvoiceTableFilterValue) => void;
  //
  serviceOptions: string[];
};

export default function MetricsTableToolbar({
  filters,
  onFilters,
  //
  serviceOptions,
}: Props) {
  const popover = usePopover();
  const [storeName, setStoreName] = useState(filters.name);
  const [countries, setCountries] = useState<any[]>([]);
  const [billingPlans, setBillingPlans] = useState<any[]>([]);
  const timeoutIdRef = useRef<any>(undefined);

  useEffect(() => {
    axios
    .get(API_ENDPOINTS.store.countryList)
    .then(({ data }) => {
      const countryList = data.map((row: any) => {
        let countryName = '';
        switch (row.country) {
          case 'AR':
            countryName = 'Argentina';
            break;
          case 'MX':
            countryName = 'México';
            break;
          case 'BR':
            countryName = 'Brasil';
            break;
          case 'CO':
          case 'COL':
            countryName = 'Colombia';
            break;
          case 'CL':
            countryName = 'Chile';
            break;
          default:
            countryName = row.country;
        }
        return { code: row.country, name: countryName };
      });
      setCountries(countryList);
    })
    .catch((error) => {
      // Manejar error
    });
}, [])

useEffect(() => {
  axios
    .get(API_ENDPOINTS.plans.list)
    .then(({ data }) => {
      setBillingPlans(data);
    });
}, [])

const handleFilterName = useCallback(
  (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target;
    setStoreName(value);
    clearTimeout(timeoutIdRef.current);
    if (value.length >= 3){
      timeoutIdRef.current = setTimeout(() => {
        // Aquí puedes realizar tu llamada a la API con el valor
        onFilters('name', value);
      }, 500);
    }
    if (value.length === 0){
      onFilters('name', event.target.value);      
    }
  },
  [onFilters]
);

  const renderFlag = (country: string) => {
    switch (country) {
      case 'AR':
        return <Iconify icon="twemoji:flag-argentina" />;
      case 'MX':
        return <Iconify icon="twemoji:flag-mexico" />;
      case 'BR':
        return <Iconify icon="twemoji:flag-brazil" />;
      case 'CO':
      case 'COL':
        return <Iconify icon="twemoji:flag-colombia" />;
      case 'CL':
        return <Iconify icon="twemoji:flag-chile" />;
      default:
        return <Box>{country}</Box>;
    }
  }


  const handleFilterStartDate = useCallback(
    (newValue: Date | null) => {
      onFilters('startDate', newValue);
    },
    []
  );

  const handleFilterEndDate = useCallback(
    (newValue: Date | null) => {
      onFilters('endDate', newValue);
    },
    []
  );

  return (
    <>
      <Stack
        spacing={2}
        alignItems={{ xs: 'flex-end', md: 'center' }}
        direction={{
          xs: 'column',
          md: 'row',
        }}
        sx={{
          p: 2.5,
          pr: { xs: 2.5, md: 1 },
        }}
      >
        <Stack width={{ xs: '100%', md: '40%' }}>
          <TextField
            fullWidth            
            value={storeName}
            onChange={handleFilterName}
            placeholder="Buscar merchant..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <FormControl
            sx={{
              flexShrink: 1,
              width: { xs: 1, md: 200 },
            }}
          >
            <InputLabel>País</InputLabel>
            <Select
              value={filters.country || ''}
              onChange={(event) => onFilters('country', event.target.value)}
              input={<OutlinedInput label="País" />}
              renderValue={(selected) => {
                const selectedCountry = countries.find((country) => country.code === selected);
                return selectedCountry ? (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {renderFlag(selectedCountry.code)}
                    <Box sx={{ ml: 0.5 }}>{selectedCountry.name}</Box>
                  </Box>
                ) : '';
              }}
              sx={{ textTransform: 'capitalize' }}
            >
              <MenuItem value="">
                <em>Todos</em>
              </MenuItem>
              {countries && countries.map((country) => (
                <MenuItem key={country.name} value={country.code}>
                  <Checkbox
                    disableRipple
                    size="small"
                    checked={filters.country === country.code}
                  />
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {renderFlag(country.code)}
                    <Box sx={{ ml: 0.5 }}>{country.name}</Box>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

        <FormControl
            sx={{
              flexShrink: 1,
              width: { xs: 1, md: 200 },
            }}
          >
            <InputLabel>Plan de pago</InputLabel>
            <Select
              value={filters.billingPlan || ''}
              onChange={(event) => onFilters('billingPlan', event.target.value)}
              input={<OutlinedInput label="Plan de pago" />}
              renderValue={(selected) => {
                const selectedPlan = billingPlans.find((plan) => plan.id === selected);
                return selectedPlan ? (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {(!filters.country || selectedPlan.country.country === filters.country) && renderFlag(selectedPlan.country.country)}
                    <Box sx={{ ml: 0.5 }}>{selectedPlan.name}</Box>
                  </Box>
                ) : '';
              }}
              sx={{ textTransform: 'capitalize' }}
            >
                <MenuItem value="">
                  <em>Todos</em>
                </MenuItem>
                {billingPlans && billingPlans.map((plan) => (
                  (filters.country === '' || filters.country === plan.country.country) && (
                    <MenuItem key={plan.id} value={plan.id}>
                      <Checkbox
                        disableRipple
                        size="small"
                        checked={filters.billingPlan === plan.id}
                      />
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {filters.country === '' ? renderFlag(plan.country.country) : null}
                        <Box sx={{ ml: 0.5 }}>{plan.name}</Box>
                      </Box>
                    </MenuItem>
                  )
                ))}
              </Select>
          </FormControl>

        <DatePicker
          label="Fecha inicio"
          value={filters.startDate ? filters.startDate : null}
          onChange={handleFilterStartDate}
          format="dd/MM/yyyy"
          maxDate={new Date()}
          slotProps={{ textField: { fullWidth: true } }}
          sx={{
            maxWidth: { md: 180 },
          }}
        />

        <DatePicker
          label="Fecha fin"
          value={filters.endDate ? filters.endDate : null}
          onChange={handleFilterEndDate}
          format="dd/MM/yyyy"
          minDate={filters.startDate || undefined}
          slotProps={{ textField: { fullWidth: true } }}
          sx={{
            maxWidth: { md: 180 },
          }}
        />

      </Stack>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            popover.onClose();
          }}
        >
          <Iconify icon="solar:printer-minimalistic-bold" />
          Print
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose();
          }}
        >
          <Iconify icon="solar:import-bold" />
          Import
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose();
          }}
        >
          <Iconify icon="solar:export-bold" />
          Export
        </MenuItem>
      </CustomPopover>
    </>
  );
}
